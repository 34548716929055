.create-account-screen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /*background: var(--gradient);*/
  position: relative;
  overflow: hidden;
}

.progress-bar {
  width: 80%;
  max-width: 600px;
  margin: 20px auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.progress-bar::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 0;
}

.progress-bar::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: calc(100% * var(--progress-percentage));
  background: white;
  transition: width 0.3s ease;
  z-index: 0;
}

.progress-dot {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 1;
  margin: 0;
  flex-shrink: 0;
}

.progress-dot.active {
  background: white;
}

.step-container {
  width: 100%;
  max-width: 500px;
  background: white;
  border-radius: var(--border-radius);
  padding: 30px;
  box-shadow: var(--shadow);
  animation: slideUp 0.5s ease-out;
}

.step-container h2 {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 30px;
  font-size: 24px;
}

/* Genre Step */
.genre-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.genre-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border: 2px solid #eee;
  border-radius: var(--border-radius);
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 120px;
}

.genre-button i {
  font-size: 32px;
  color: var(--light-text);
}

.genre-button span {
  font-size: 16px;
  color: var(--text-color);
}

.genre-button:hover {
  transform: translateY(-2px);
  border-color: var(--primary-color);
}

.genre-button.selected {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.genre-button.selected i,
.genre-button.selected span {
  color: white;
}

/* Pseudo Step */
.pseudo-step form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-field {
  width: 100%;
  padding: 15px;
  border: 2px solid #eee;
  border-radius: var(--border-radius);
  font-size: 16px;
  transition: all 0.3s ease;
}

.input-field:focus {
  border-color: var(--primary-color);
  outline: none;
}

.next-button {
  background: var(--gradient);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.next-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 68, 88, 0.2);
}

/* Age Step */
.age-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.age-button {
  padding: 15px;
  border: 2px solid #eee;
  border-radius: var(--border-radius);
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  text-align: left;
}

.age-button:hover {
  border-color: var(--primary-color);
}

.age-button.selected {
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: white;
}

/* Photo Step */
.photo-upload-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.upload-placeholder {
  width: 200px;
  height: 200px;
  border: 2px dashed #eee;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-placeholder:hover {
  border-color: var(--primary-color);
}

.upload-placeholder i {
  font-size: 48px;
  color: var(--light-text);
}

.preview-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.photo-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.change-photo-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.change-photo-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Objectif Step */
.objectif-step {
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  padding-bottom: 20px;
}

.objectifs-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  padding: 10px;
}

.objectif-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 15px;
  border: none;
  border-radius: var(--border-radius);
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: var(--shadow);
  text-align: left;
  min-height: 80px;
}

.objectif-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.objectif-card.selected {
  background: var(--card-color);
  color: white;
}

.objectif-icon {
  width: 60px;
  height: 60px;
  object-fit: contain;
  flex-shrink: 0;
}

.objectif-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  flex-grow: 1;
}

@media (max-width: 480px) {
  .objectifs-grid {
    gap: 10px;
  }

  .objectif-card {
    padding: 12px;
    gap: 15px;
  }

  .objectif-icon {
    width: 48px;
    height: 48px;
  }

  .objectif-title {
    font-size: 16px;
  }
}

/* Recap Step */
.recap-step {
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  padding-bottom: 20px;
}

.recap-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}

.recap-photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--primary-color);
  flex-shrink: 0;
}

.recap-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recap-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.recap-info p {
  padding: 12px 15px;
  background: #f8f9fa;
  border-radius: var(--border-radius);
  font-size: 16px;
  margin: 0;
}

.ephemeral-message {
  background: linear-gradient(135deg, #ff6b81 0%, #ff4458 100%);
  border-radius: 12px;
  padding: 1.5rem;
  margin: 1.5rem 0;
  color: white;
  text-align: center;
  box-shadow: 0 4px 15px rgba(255, 68, 88, 0.2);
  position: relative;
  overflow: hidden;
}

.ephemeral-message::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
}

.ephemeral-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  animation: sand-fall 2s infinite ease-in-out;
}

@keyframes sand-fall {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ephemeral-message p {
  margin: 0.5rem 0;
  line-height: 1.4;
  font-size: 1rem;
}

.ephemeral-highlight {
  font-style: italic;
  font-weight: 500;
  font-size: 1.1rem !important;
  margin-top: 1rem !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.recap-step h2 {
  color: #FF4458;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
}

.submit-button {
  background: linear-gradient(135deg, #ff6b81 0%, #ff4458 100%);
  color: white;
  border: none;
  border-radius: 25px;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 15px rgba(255, 68, 88, 0.2);
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 68, 88, 0.3);
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Notification Step */
.notifications-step {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.notifications-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
}

.notification-icon {
  font-size: 3rem;
  color: #FF4458;
  animation: bell-ring 2s ease-in-out infinite;
}

@keyframes bell-ring {
  0%, 100% {
    transform: rotate(0);
  }
  10%, 30%, 50% {
    transform: rotate(10deg);
  }
  20%, 40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(0);
  }
}

.notification-description {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #666;
  margin: 0;
}

.notification-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
}

.enable-button {
  background-color: #FF4458;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.enable-button:hover {
  background-color: #e63950;
}

.skip-button {
  background: none;
  border: none;
  color: #666;
  padding: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: underline;
}

.skip-button:hover {
  color: #333;
}

.notification-blocked {
  background-color: #fff3f4;
  border: 1px solid #ffcdd2;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
}

.notification-warning {
  color: #d32f2f;
  font-size: 0.9rem;
  margin: 0 0 1rem 0;
  line-height: 1.4;
}

.notification-success {
  text-align: center;
  padding: 20px;
  background-color: rgba(40, 167, 69, 0.1);
  border-radius: 8px;
  margin-top: 20px;
}

.notification-success p {
  color: #28a745;
  font-size: 1.1em;
  margin-bottom: 15px;
}

.notification-success i {
  margin-right: 8px;
}

.continue-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.continue-button:hover {
  background-color: #218838;
}

/* Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .step-container {
    padding: 20px;
  }

  .genre-buttons {
    flex-direction: column;
    align-items: center;
  }

  .genre-button {
    width: 100%;
    max-width: 200px;
  }

  .objectifs-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .recap-step {
    padding: 15px;
  }

  .recap-photo {
    width: 100px;
    height: 100px;
  }

  .recap-info p {
    font-size: 14px;
    padding: 10px 12px;
  }

  .submit-button {
    font-size: 16px;
    padding: 12px;
  }
}
