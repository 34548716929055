@import url('Components.css');

.notification-message {
  font-size: 0.9rem;
  color: #333;
  margin-top: 2px;
  word-break: break-word;
}

.notification-message:has(+ [data-content="Photo ou lien"]),
.notification-message:contains("Photo ou lien") {
  color: #2196F3;
  font-style: italic;
}