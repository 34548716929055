.statistics-screen {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .statistics-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 30px;
  }
  
  .statistic-card {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .statistic-card:hover {
    transform: translateY(-5px);
  }
  
  .statistic-card h2 {
    color: #333333;
    margin-bottom: 10px;
  }
  
  .statistic-card p {
    color: #666666;
    margin: 0;
  }