.tv-display {
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 40px;
  background: 
    radial-gradient(
      circle at center,
      #000 0%,
      #000 85%,
      rgba(0, 0, 0, 0.8) 100%
    );
  color: #fff;
  overflow: hidden;
  position: relative;
  border-radius: 50px;
  transform: perspective(1000px) rotateX(2deg);
  box-shadow: 
    inset 0 0 50px rgba(0, 0, 0, 0.7),
    inset 0 0 100px rgba(255, 255, 255, 0.05),
    0 0 0 10px #333,
    0 0 0 20px #222,
    0 0 0 30px #111;
  box-sizing: border-box;
}

/* Hide scrollbars for all elements */
.tv-display *::-webkit-scrollbar {
  display: none !important;
}

.tv-display * {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.tv-display::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(
      circle at 50% 50%,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url('../img/logo.png');
  background-repeat: repeat;
  background-size: 300px;
  opacity: 0.1;
  z-index: 1;
  pointer-events: none;
  animation: rotateBg 120s linear infinite;
  border-radius: 40px;
}

.tv-display::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background: linear-gradient(
    rgba(18, 16, 16, 0) 50%,
    rgba(0, 0, 0, 0.10) 50%
  );*/
  background-size: 100% 4px;
  pointer-events: none;
  z-index: 10;
}

.tv-display .messages-container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 80px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  padding: 2vh;
  gap: 2vh;
  padding-bottom: 60px !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  overflow-y: auto;
}

.tv-display .messages-container::-webkit-scrollbar {
  display: none; /* Pour Chrome, Safari et Opera */
}

.messages-container,
.messages-section,
.content {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  overflow-y: auto;
}

/* Style de base pour tous les messages */
.tv-display .message-card {
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid var(--primary-color);
  border-radius: 20px;
  box-shadow: 0 0 30px var(--primary-color);
  animation: glowPulse 2s infinite;
}

/* Style spécifique pour les messages sans image */
.tv-display .message-card:not(:has(img)) {
  /*min-height: 15vh;*/
  padding: 2vh 4vw;
}

/* Style spécifique pour les messages avec image */
.tv-display .message-card:has(img) {
  padding: 2vh;
}

.tv-display .message-content {
  font-size: 4vh;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-shadow: 0 0 10px var(--primary-color);
  margin: 0;
  line-height: 1.2;
  background-color: #000;
  padding: 1vh;
  border-radius: 10px;
}

/* Style pour les images */
.tv-display .message-image {
  width: auto;
  height: auto;
  max-width: 40vw;
  max-height: 40vh;
  object-fit: contain;
  border-radius: 10px;
  margin-top: 1vh;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
}

.tv-display .message-time {
  font-size: 2vh;
  color: var(--primary-color);
  margin-top: 1vh;
  font-weight: bold;
}

/* Animations */
@keyframes glowPulse {
  0% {
    box-shadow: 0 0 30px var(--primary-color);
  }
  50% {
    box-shadow: 0 0 50px var(--primary-color), 0 0 80px var(--secondary-color);
  }
  100% {
    box-shadow: 0 0 30px var(--primary-color);
  }
}

@keyframes rotateBg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(50px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes neonPulse {
  from {
    text-shadow: 
      0 0 5px #ffff00,
      0 0 10px #ffff00,
      0 0 20px #ffff00,
      0 0 40px #00ff00;
  }
  to {
    text-shadow: 
      0 0 2px #ffff00,
      0 0 5px #ffff00,
      0 0 10px #ffff00,
      0 0 20px #00ff00;
  }
}

/* Masquer les éléments non nécessaires pour l'affichage TV */
.tv-display .header,
.tv-display .stats-summary,
.tv-display .message-user,
.tv-display .message-header {
  display: none;
}

/* Style du scrollbar pour le défilement fluide */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

/* Animation d'entrée des messages */
.tv-display .message-card {
  animation: slideIn 0.5s ease-out forwards;
}

/* Style pour les messages d'erreur */
.tv-display .error {
  font-size: 4vh;
  color: #ff0000;
  text-shadow: 0 0 20px #ff0000;
  text-align: center;
}

/* Style pour le chargement */
.tv-display .loading {
  font-size: 4vh;
  color: var(--primary-color);
  text-shadow: 0 0 20px var(--primary-color);
  text-align: center;
}

.live-indicator {
  position: fixed;
  bottom: 20px;
  right: 30px;
  font-family: 'Brush Script MT', cursive;
  font-size: 2.5rem;
  color: #ffff00;
  text-shadow: 
    0 0 5px #ffff00,
    0 0 10px #ffff00,
    0 0 20px #ffff00,
    0 0 40px #00ff00;
  animation: neonPulse 1.5s ease-in-out infinite alternate;
  z-index: 11;
}

body {
  background: #000;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
