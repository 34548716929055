@import url('ScreensStyles.css');

.screen-content {
  position: relative;
  height: 100vh;
  overflow-y: auto;
}

.participants-screen {
  height: calc(100vh );
 /* margin-top: var(--navbar-height);*/
  overflow-y: auto;
 padding-top: calc(var(--navbar-height) + 10px);
 padding-bottom: var(--navbar-height);
  /*background-color: rgba(250, 204, 211, 0.635); */
  position: relative;
 
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--text-light);
  font-size: var(--font-size-md);
}

.participants-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-lg);
}

.participants-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-xl);
  margin: 0 auto;
  max-width: 1400px;
  padding: 0;
}

.participants-column {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.participants-column.center {
  margin-top: var(--spacing-xl);
}

.participant-card {
  text-align: center;
  cursor: pointer;
  will-change: transform;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: float 4s ease-in-out infinite;
  transition: var(--transition);
  position: relative;
  margin-bottom: var(--spacing-xs);
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.participant-card:hover {
  animation: none;
  transform: translateY(-12px);
  transition: var(--transition);
}

.participant-avatar-container {
  position: relative;
  width: 160px;
  height: 160px;
  margin: 0 auto var(--spacing-sm);
}

.participant-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid transparent;
  box-sizing: border-box;
  transition: var(--transition);
}

.bottom-indicators {
  position: absolute;
  bottom: -24px;
  right: -4px;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: var(--card-background);
  padding: 2px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.participant-number {
  font-size: 0.75em;
  font-weight: 600;
  padding: 3px 8px;
  border-radius: 10px;
  color: white;
}

.participant-card.male .participant-number {
  background-color: var(--male-color, #4A90E2);
}

.participant-card.female .participant-number {
  background-color: var(--female-color, #FF6B81);
}

.location-indicator {
  background-color: var(--card-background);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-indicator .objectif-emoji {
  font-size: 1em;
}

.notification-dot {
  position: absolute;
  top: 0;
  right: 15px;
  width: 12px;
  height: 12px;
  background-color: #ff3b30;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.participant-avatar.male {
  border-color: #44b3ff6e;
}

.participant-avatar.female {
  border-color: var(--secondary-color);
}

.objectif-emoji {
  font-size: var(--font-size-md);
}

.participant-name {
  margin-top:20px;
  font-size: 1.1em;
  /*margin-top: var(--spacing-xs);*/
  font-weight: 500;
  color: var(--text);
  display:none;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99000;
  backdrop-filter: blur(5px);
}

.popup-content {
  background: transparent;
  border-radius: var(--border-radius-lg);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: popup-appear 0.3s ease-out;
}

.popup-background-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(8px);
  z-index: -1;
}

.popup-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, 
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.75) 100%
  );
  z-index: 0;
}

.popup-header {
  padding: var(--spacing-lg);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  color: white;
}

.popup-header h2 {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  font-size: var(--font-size-xl);
  margin: 0;
}

.popup-header .participant-number {
  font-size: 2em;
  color: white;
}

.popup-header .objectif-emoji {
  font-size: 2em;
}

.popup-body {
  padding: var(--spacing-lg);
  color: white;
  position: relative;
  z-index: 1;
}

.participant-photo {
  width: 120px;
  height: 120px;
  margin: 0 auto var(--spacing-lg);
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid white;
}

.participant-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
}

.participant-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.detail-item .label {
  color: rgba(255, 255, 255, 0.8);
  font-size: var(--font-size-sm);
}

.detail-item .value {
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.detail-item .value.numero {
  font-size: var(--font-size-lg);
  font-weight: 600;
}

.popup-footer {
  padding: var(--spacing-lg);
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));
}

.chat-button {
  background: white;
  color: var(--primary-color);
  border: none;
  padding: var(--spacing-md) var(--spacing-xl);
  border-radius: var(--border-radius);
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  width: 100%;
  max-width: 200px;
}

.chat-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.chat-button.male {
  color: #44b3ff;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.close-button:hover {
  opacity: 1;
}

.filters-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 20px;
  background: linear-gradient(145deg, rgba(255,68,88,0.15) 0%, rgba(255,107,129,0.1) 100%);
  border-radius: 15px;
  margin: 0 20px 25px;
  box-shadow: 0 4px 20px rgba(255,68,88,0.1);
}

.filter-group {
  flex: 1;
  min-width: 250px;
}

.filter-input, .filter-select {
  position: relative;
  background: rgba(255,255,255,0.05);
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255,68,88,0.3);
  
}

.filter-input:hover, .filter-select:hover {
  border-color: #FF4458;
  box-shadow: 0 0 15px rgba(255,68,88,0.2);
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background: #2a2a2a;
  border-radius: 8px;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 100;
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
}

.dropdown-item {
  padding: 12px 20px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dropdown-item:hover {
  background: rgba(255,68,88,0.2);
  color: #FF4458;
}

.objective-select {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background-color: var(--background);
  color: var(--text);
  font-size: var(--font-size-md);
  cursor: pointer;
  outline: none;
}

.objective-select option {
  padding: 8px;
  background-color: var(--background);
  color: var(--text);
}

.objective-select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.1);
}

.filters-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--card-background);
  padding: 2rem;
  box-shadow: none;
  z-index: 9999;
  transform-origin: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.filters-menu.hidden {
  transform: scale(0.95);
  opacity: 0;
  pointer-events: none;
}

.filters-menu h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.5rem;
  color: var(--text-light);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.filters-menu .filter-section {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 2rem;
}

.filters-menu .filter-section:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.filters-menu .filter-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.filters-menu .filter-option {
  padding: 1rem;
  border-radius: 25px;
  background: var(--background);
  color: var(--text);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  gap: 0.75rem;
  white-space: normal;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 1rem;
  text-align: left;
  min-height: 3.5rem;

  padding: 1rem 1.5rem;
  border: none;
  background: rgba(255, 68, 88, 0.1);
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 68, 88, 0.2);
  min-width: 80px;
  text-align: center;
}

.filters-menu .filter-option .objectif-emoji {
  font-size: 1.6em;
  flex-shrink: 0;
}

.filters-menu .filter-option .objectif-text {
  font-size: 1em;
  line-height: 1.2;
}

.filters-menu .filter-option:hover {
  background: rgba(255, 68, 88, 0.15);
  border-color: rgba(255, 68, 88, 0.3);
  transform: translateY(-2px);
}

.filters-menu .filter-option.active {
  background: linear-gradient(135deg, #FF4458, #FF6B81);
  transform: scale(1.05);
  color: white;
  border-color: transparent;
  box-shadow: 
}

.gender-filter {
  font-size: 1.8rem;
  padding: 1rem 1.5rem;
  border: none;
  background: rgba(255, 68, 88, 0.1);
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 68, 88, 0.2);
  min-width: 80px;
  text-align: center;
}

.gender-filter:hover {
  background: rgba(255, 68, 88, 0.15);
  transform: translateY(-2px);
}

.gender-filter.active {
  background: linear-gradient(135deg, #FF4458, #FF6B81);
  transform: scale(1.05);
  color: white;
  border-color: transparent;
  box-shadow: 0 4px 12px rgba(255, 68, 88, 0.3);
}

.floating-filter-button {
  position: fixed;
  bottom: 6rem;
  right: 2rem;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: linear-gradient(135deg, #FF4458, #FF6B81);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0 4px 12px rgba(255, 68, 88, 0.3);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 10000;
}

@media (max-width: 768px) {
  .floating-filter-button {
    bottom: 6rem;
    right: 1.5rem;
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 380px) {
  .floating-filter-button {
    bottom: 6rem;
    right: 1rem;
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .participants-screen {
   /* padding: var(--spacing-sm);
    margin-top: var(--spacing-md);*/
  }

  .participants-container {
    padding: var(--spacing-sm);
  }

  .participants-columns {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-sm);
  }

  .participant-avatar-container {
    width: 80px;
    height: 80px;
  }

  .participant-name {
    font-size: var(--font-size-sm);
  }

  .location-indicator {
    width: 25px;
    height: 25px;
  }

  .objectif-emoji {
    font-size: var(--font-size-sm);
  }
}

@media (max-width: 768px) {
  .filters-menu {
    padding: 1.5rem;
    gap: 1.5rem;
  }

  .filters-menu h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .filters-menu .filter-section {
    padding-bottom: 1.5rem;
  }

  .filters-menu .filter-options {
    gap: 1rem;
  }
}

@media (max-width: 380px) {
  .filters-menu {
    padding: 1rem;
    gap: 1rem;
  }

  .filters-menu .filter-options {
    gap: 0.75rem;
  }
}