/* Importation des autres fichiers CSS */
@import url('variables.css'); /* Variables de couleurs, bordures, etc. */

/* Styles pour l'écran de chat */
.chat-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /*background-color: var(--background-color);*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Navbar */
.navbar {
  position: fixed;
  top: 5;
  height: var(--navbar-height);
  border-bottom: 1px solid var(--border-color);
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-md);
}

.navbar .nav-link {
  color: var(--text-color);
  cursor: pointer;
  padding: var(--spacing-xs) var(--spacing-md);
  border: none;
  background: none;
  font-size: var(--font-size-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  transition: var(--transition);
}

.navbar .nav-link:hover {
  color: var(--primary-color);
}

.navbar .nav-link i {
  font-size: var(--font-size-lg);
}

/* Chat Header */
.chat-header {
  background-color: var(--card-background);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-sm);
  z-index: 10;
}

/* Messages */
.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  /*margin-top: var(--navbar-height);*/
  margin-bottom: 80px;
  height: calc(100vh - calc(var(--navbar-height) + 80px));

}

.message {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-xs);
  max-width: 80%;
  opacity: 1;
  transform: scale(1) translateY(0);
}

.message-mine {
  margin-left: auto;
  flex-direction: row-reverse;
}

.message-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.message-content {
  background-color: var(--card-background);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
  position: relative;
}

.message-mine .message-content {
  background-color: var(--primary-color);
  color: white;
}

.message-text {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
}

.message-time {
  font-size: var(--font-size-xs);
  color: var(--text-light);
  margin-top: var(--spacing-xs);
  display: block;
}

.message-mine .message-time {
  color: rgba(255, 255, 255, 0.8);
}

.message-image-container {
  max-width: 300px;
}

.message-image {
  width: 100%;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}

/* Input et bouton */
.message-input-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-md);
  background-color: var(--card-background);
  border-top: 1px solid var(--border-color);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.message-input {
  flex: 1;
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
  outline: none;
  font-size: var(--font-size-md);
  transition: var(--transition);
}

.message-input:focus {
  border-color: var(--primary-color);
  box-shadow: var(--shadow-sm);
}

/* Animation pour les nouveaux messages */
.message.new-message.animate {
  animation: message-pop 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

/* Animation de message */
@keyframes message-pop {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(20px);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05) translateY(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* Formulaires et boutons */
.button {
  padding: 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: var(--secondary-color);
}

/* Inputs */
.input-field {
  width: 100%;
  padding: 16px;
  border-radius: var(--border-radius);
  background-color: rgba(255, 255, 255, 0.95);
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.input-field:focus {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  outline: none;
  background-color: white;
}

.input-field::placeholder {
  color: var(--light-text);
  font-weight: normal;
}

/* Autres composants */
.nav-link.active {
  background: var(--primary-color-light);
  color: white;
}

.nav-link i.active {
  transform: scale(1.1);
  color: white;
}

/* Modifications de la barre d'outils */
.message-input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* Comportement des boutons */
.attach-button,
.send-button {
  background: none;
  border: none;
  color: var(--primary-color);
  padding: var(--spacing-sm);
  cursor: pointer;
  font-size: var(--font-size-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}

.attach-button:hover,
.send-button:hover {
  color: var(--secondary-color);
}

.attach-button:disabled,
.send-button:disabled {
  color: var(--text-lighter);
  cursor: not-allowed;
}

/* Styles des popup et transitions */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.popup-content {
  background: var(--card-background);
  border-radius: var(--border-radius-lg);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: popup-appear 0.3s ease-out;
  box-shadow: var(--shadow-lg);
}

@keyframes popup-appear {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Styles de bouton secondaire */
.popup-button.secondary {
  background-color: var(--card-background);
  color: var(--text-color);
  box-shadow: var(--shadow-sm);
}

/* Transition des filtres */
.filters-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--card-background);
  padding: 2rem;
  box-shadow: none;
  z-index: 9999;
  transform-origin: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.filters-menu.hidden {
  transform: scale(0.95);
  opacity: 0;
  pointer-events: none;
}

.filters-menu h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.5rem;
  color: var(--text-light);
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Composants supplémentaires */
.filter-option:hover {
  background: rgba(255, 68, 88, 0.15);
  border-color: rgba(255, 68, 88, 0.3);
  transform: translateY(-2px);
}

.filter-option.active {
  background: rgba(255, 68, 88, 0.2);
  border-color: rgba(255, 68, 88, 0.5);
  color: #FF4458;
  font-weight: 500;
  transform: scale(1.05);
}
