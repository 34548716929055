.profile-screen {
  height: calc(100vh - 60px);
  margin-top: 60px;
  overflow-y: auto;
  padding: 20px;
  /*background-color: #fff;*/
  position: relative;
  padding-bottom: 70px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  color: #6c5ce7;
  font-size: 16px;
}

.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
  color: #dc3545;
}

.error-message button {
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error-message button:hover {
  background-color: #c82333;
}

.profile-content {
  max-width: 600px;
  margin: 0 auto;
}

.profile-header {
  text-align: center;
  margin-bottom: 30px;
}

.profile-photo-container {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto 20px;
  transition: transform 0.3s ease;
}

.profile-photo-container:hover {
  transform: scale(1.02);
}

.profile-photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.profile-photo:hover {
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.4);
}

.photo-upload-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 45px;
  height: 45px;
  background-color: rgba(108, 92, 231, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.photo-upload-button:hover {
  background-color: rgba(108, 92, 231, 1);
  transform: scale(1.1);
}

.photo-upload-button i {
  color: white;
  font-size: 20px;
}

.profile-header h2 {
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  font-size: 28px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.profile-details {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  backdrop-filter: blur(5px);
  padding: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  font-size: 0.95rem;
}

.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
}

.form-group select:hover,
.form-group textarea:hover {
  border-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.15);
}

.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: rgba(108, 92, 231, 0.6);
  background: rgba(255, 255, 255, 0.2);
}

.button-group {
  display: flex;
  gap: 15px;
  margin-top: 30px;
}

.cancel-button,
.save-button,
.edit-button {
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.cancel-button {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.cancel-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-1px);
}

.save-button,
.edit-button {
  background: rgba(108, 92, 231, 0.8);
  color: white;
  flex: 1;
  box-shadow: 0 4px 15px rgba(108, 92, 231, 0.3);
}

.save-button:hover,
.edit-button:hover {
  background: rgba(108, 92, 231, 1);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(108, 92, 231, 0.4);
}

.info-group {
  margin-bottom: 25px;
}

.info-group label {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  margin-bottom: 8px;
}

.info-group p {
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  font-size: 1rem;
}

.logout-button {
  margin-top: 30px;
  width: 100%;
  padding: 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 15px rgba(220, 53, 69, 0.3);
}

.logout-button:hover {
  background: rgba(220, 53, 69, 1);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(220, 53, 69, 0.4);
}

.notification-group {
  margin: 1.5rem 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.toggle-button {
  background: rgba(224, 224, 224, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 8px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.8);
}

.toggle-button.enabled {
  background: rgba(76, 175, 80, 0.8);
  color: white;
  box-shadow: 0 2px 10px rgba(76, 175, 80, 0.3);
}

.toggle-button:hover {
  transform: translateY(-1px);
}

.notification-warning {
  margin-top: 15px;
  color: rgba(231, 76, 60, 0.9);
  background: rgba(231, 76, 60, 0.1);
  padding: 12px;
  border-radius: 8px;
  border: 1px solid rgba(231, 76, 60, 0.2);
}

.request-permission-button,
.test-notification-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.request-permission-button {
  background: rgba(52, 152, 219, 0.8);
  color: white;
  box-shadow: 0 2px 10px rgba(52, 152, 219, 0.3);
}

.request-permission-button:hover {
  background: rgba(52, 152, 219, 1);
  transform: translateY(-1px);
  box-shadow: 0 4px 15px rgba(52, 152, 219, 0.4);
}

.test-notification-button {
  background: rgba(46, 204, 113, 0.8);
  color: white;
  box-shadow: 0 2px 10px rgba(46, 204, 113, 0.3);
  margin-left: 10px;
}

.test-notification-button:hover {
  background: rgba(46, 204, 113, 1);
  transform: translateY(-1px);
  box-shadow: 0 4px 15px rgba(46, 204, 113, 0.4);
}
