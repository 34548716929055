:root {
  --primary-color: #FF4458;
  --secondary-color: #FF8E9E;
  --background-color: #f5f7fa;
  --card-background: #ffffff;
  --text-color: #424242;
  --text-light: #757575;
  --text-lighter: #999999;
  --border-color: #ddd;
  --border-radius-sm: 4px;
  --border-radius: 12px;
  --border-radius-lg: 20px;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  --shadow-lg: 0 4px 20px rgba(0, 0, 0, 0.12);
  --gradient: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  --navbar-height: 60px;
  --content-padding: 20px;
  --transition: all 0.3s ease;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 24px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.app-wrapper {
  background: radial-gradient(circle at center, #9b1c31 0%, #2c0202 100%);
  position: relative;
  overflow: hidden;
  height: 100vh;
  z-index: 0;
}

.app-container {
  /*height: 100%;*/
  /*background-color: #FFF;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;  /* S'assurer que le contenu principal est au-dessus */
}

.screen-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 80px; /* Space for navbar */
  margin-top: 0;
  height: calc(100vh - 60px);
  /*background-color: white;*/
  
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Styles communs */
.button {
  background: var(--gradient);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  padding: var(--spacing-md) var(--spacing-xl);
  font-size: var(--font-size-md);
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.input {
  width: 100%;
  padding: var(--spacing-md);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: var(--font-size-md);
  transition: var(--transition);
  background-color: var(--card-background);
  color: var(--text-color);
}

.input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: var(--shadow-sm);
}

.input::placeholder {
  color: var(--text-lighter);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in;
}

/* Messages d'erreur */
.error-message {
  color: var(--primary-color);
  background: rgba(255, 255, 255, 0.9);
  padding: 12px;
  border-radius: var(--border-radius);
  margin: 10px 0;
  font-size: var(--font-size-md);
}

/* Loading spinner */
.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--border-color);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Styles globaux */
.btn {
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-sm);
  border: none;
  cursor: pointer;
  font-size: var(--font-size-md);
  transition: var(--transition);
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: var(--secondary-color);
}

.btn-secondary {
  background-color: var(--text-light);
  color: white;
}

.btn-secondary:hover {
  background-color: var(--text-color);
}

/* Styles spécifiques à l'application */
.app {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

/* Navigation */
.navbar {
  position: fixed;
  bottom: 0;
/*  left: 0;
  right: 0;*/
  height: 60px;
  /*background-color: white;*/
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: 0;
  padding: 0;
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  text-decoration: none;
  border: none;
  background: none;
  padding: 5px;
  min-width: 80px;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: var(--text-color);
}

.nav-link.active {
  color: var(--primary-color);
}

.nav-link i {
  font-size: 1.25rem;
  margin-bottom: 4px;
}

.nav-link span {
  font-size: 0.75rem;
}

/* Conteneur principal */
.main-container {
  width: 100%;
  margin: 0;
  padding: 0;
  height: calc(100vh - var(--navbar-height));
  overflow-y: auto;
}

/* Cartes */
.card {
  width: 100%;
  margin: 0 0 1rem 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-header {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.card-body {
  padding: 1rem;
}

/* Formulaires */
.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.form-control:focus {
  outline: none;
  border-color: var(--primary-color);
}

/* Loading */
.loading-spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.notifications-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  gap: 10px;
  pointer-events: none;
}

.notifications-container > * {
  pointer-events: auto;
}

/* Animation de petits cœurs */
@keyframes floatHearts {
  0% {
    transform: translateY(100vh) scale(0.8);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh) scale(1.2);
    opacity: 0;
  }
}

.heart {
  position: absolute;
  color: rgba(255, 105, 180, 0.8);
  font-size: 64px;
  animation: floatHearts 15s linear infinite;
  bottom: -20px;
  z-index: 1;  /* Mettre les cœurs en arrière-plan */
}

.heart:nth-child(1) {
  left: 10%;
  animation-duration: 18s;
  animation-delay: 0s;
}

.heart:nth-child(2) {
  left: 30%;
  animation-duration: 16s;
  animation-delay: 2s;
}

.heart:nth-child(3) {
  left: 50%;
  animation-duration: 20s;
  animation-delay: 1s;
}

.heart:nth-child(4) {
  left: 70%;
  animation-duration: 17s;
  animation-delay: 3s;
}

.heart:nth-child(5) {
  left: 90%;
  animation-duration: 19s;
  animation-delay: 2.5s;
}
