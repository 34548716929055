/* Variables pour couleurs et bordures */
:root {
  --primary-bg: rgba(0, 123, 255, 0.2);
  --primary-color: #4facfe;
  --hover-color: #00f2fe;
  --border-radius: 10px;
  --box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  --white: #fff;
  --gray-light: #f5f5f5;
  --gray-dark: #333;
  --gray-medium: #555;
}

/* Install Modal */
.install-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.install-modal-content {
  background: var(--white);
  padding: 20px;
  border-radius: var(--border-radius);
  text-align: center;
  max-width: 400px;
  box-shadow: var(--box-shadow);
}

.install-modal-content h2 {
  margin-top: 0;
  color: var(--gray-dark);
}

.install-modal-content p {
  font-size: 16px;
  color: var(--gray-medium);
}

.install-modal-content button {
  margin: 10px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background: var(--primary-color);
  color: var(--white);
  border-radius: 5px;
  font-size: 16px;
  transition: background 0.2s;
}

.install-modal-content button:hover {
  background: var(--hover-color);
}

/* Message Notification */
.message-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  z-index: 1000;
  max-width: 400px;
  animation: slide-in 0.3s ease-out;
  border-left: 4px solid #007bff;
  transition: transform 0.2s, box-shadow 0.2s;
}

.message-notification:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.message-notification.show {
  display: flex;
}

.message-notification.hide {
  animation: slide-out 0.3s ease-out forwards;
}

.notification-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e1e1e1;
  flex-shrink: 0;
}

.notification-content {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.notification-emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background: var(--gray-light);
}

.notification-text {
  flex: 1;
  min-width: 0;
}

.notification-sender {
  font-weight: 600;
  color: var(--gray-dark);
  margin-bottom: 4px;
}

.notification-message {
  color: #666;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-close {
  background: none;
  border: none;
  color: #999;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin-left: 8px;
  opacity: 0.6;
  transition: opacity 0.2s;
  z-index: 2;
}

.notification-close:hover {
  opacity: 1;
}

/* Navbar */
.navbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  height: 70px;
  width: 260px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  text-decoration: none;
  border: none;
  background: none;
  padding: 8px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.nav-link.active {
  background: var(--primary-bg);
  color: var(--primary-color);
}

.nav-link i {
  font-size: 28px;
  transition: transform 0.2s ease-in-out;
}

.nav-link.active i {
  transform: scale(1.1);
  color: var(--primary-color);
}

.notification-dot {
  position: absolute;
  top: 6px;
  right: 8px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid var(--white);
}

.nav-link.active::before {
  content: '';
  position: absolute;
  width: 55px;
  height: 55px;
  background: rgba(0, 123, 255, 0.15);
  border-radius: 50%;
}

/* User Notification */
.user-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  max-width: 300px;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  animation: slideIn 0.3s ease-out;
  border-left: 4px solid #4CAF50;
}

.user-notification.show {
  display: flex;
}

.user-notification.hide {
  animation: slideOut 0.3s ease-out forwards;
}

/* Animation */
@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
