.conversations-screen {
  height: calc(100vh - 60px);
  margin-top: 60px;
  overflow-y: auto;
  padding: 20px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #666;
  font-size: 16px;
}

.error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #dc3545;
  gap: 20px;
  text-align: center;
}

.error-message button {
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error-message button:hover {
  background-color: #c82333;
}

.conversations-list {
  max-width: 800px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  padding: 10px;
}

.conversation-item {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 10px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.conversation-item:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.conversation-avatar-container {
  margin-right: 20px;
  position: relative;
}

.conversation-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.conversation-item:hover .conversation-avatar {
  border-color: rgba(255, 255, 255, 0.4);
  transform: scale(1.05);
}

.conversation-content {
  flex: 1;
  min-width: 0;
}

.conversation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.conversation-name {
  font-weight: 600;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.conversation-time {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.6);
}

.conversation-message {
  margin-top: 4px;
}

.message-preview {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  line-height: 1.5;
}

.conversation-item.unread {
  background: rgba(255, 255, 255, 0.15);
}

.conversation-item.unread .message-preview {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

.message-preview[data-content="Photo ou lien"] {
  color: #2196F3;
  font-style: italic;
}

.notification-dot {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 14px;
  height: 14px;
  background-color: #ff3b30;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.unread-count {
  background-color: #ff3b30;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.conversation-emoji {
  position: absolute;
  bottom: -4px;
  right: -4px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 2px solid rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.conversation-item:hover .conversation-emoji {
  transform: scale(1.1);
}

.no-conversations {
  text-align: center;
  padding: 40px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
}

.start-chat-btn {
  margin-top: 20px;
  padding: 12px 30px;
  background-color: rgba(108, 92, 231, 0.8);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 15px rgba(108, 92, 231, 0.3);
}

.start-chat-btn:hover {
  background-color: rgba(108, 92, 231, 1);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(108, 92, 231, 0.4);
}
