.home-screen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  /*background: var(--gradient);*/
  position: relative;
  overflow: auto !important;
  height: auto;
}

/* Animation de bienvenue */
.welcome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--gradient);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.5s ease-out;
  pointer-events: none;
}

.welcome-overlay.visible {
  opacity: 1;
}

.welcome-overlay:not(.visible) {
  opacity: 0;
}

.welcome-logo {
  width: 200px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Formes d'arrière-plan */
.background-shapes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
}

.shape {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.shape-1 {
  width: 300px;
  height: 300px;
  top: -150px;
  right: -150px;
  animation: float 20s infinite;
}

.shape-2 {
  width: 200px;
  height: 200px;
  bottom: -100px;
  left: -100px;
  animation: float 15s infinite reverse;
}

.shape-3 {
  width: 150px;
  height: 150px;
  top: 50%;
  right: -75px;
  animation: float 18s infinite;
}

@keyframes float {
  0% { transform: translate(0, 0) rotate(0deg); }
  33% { transform: translate(30px, -30px) rotate(120deg); }
  66% { transform: translate(-20px, 20px) rotate(240deg); }
  100% { transform: translate(0, 0) rotate(360deg); }
}

/* Logo */
.logo-container {
  margin-bottom: 40px;
  animation: fadeIn 1s ease-out;
}

.logo {
  max-width: 180px;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

/* Formulaire */
.login-form {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  animation: fadeIn 1s ease-out 0.3s backwards;
}

.back-button {
  position: absolute;
  top: 56px;
  left: 12px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  z-index: 1;
}

.back-button-login {
  position: absolute;
  top: 36px;
  left: -20px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  z-index: 1;
}

.back-button:hover {
  transform: translateY(-50%) translateX(-3px);
}

.back-button:focus {
  outline: none;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-field {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: var(--border-radius);
  background-color: rgba(255, 255, 255, 0.95);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.input-field:focus {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  outline: none;
  background-color: white;
}

.input-field::placeholder {
  color: var(--light-text);
  font-weight: normal;
}

.input-info {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

/* Bouton */
.button {
  position: relative;
  width: 100%;
  padding: 16px;
  background-color: white;
  color: white;
  border: none;
  border-radius: var(--border-radius);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.button.loading {
  color: transparent;
  pointer-events: none;
}

.button-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border: 2px solid var(--primary-color);
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

/* Message d'erreur */
.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 12px 20px;
  border-radius: var(--border-radius);
  font-size: 14px;
  backdrop-filter: blur(10px);
  animation: fadeIn 0.3s ease-out;
}

.error-message i {
  font-size: 16px;
}

/* Version */
.version-info {
  position: absolute;
  bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

/* Transitions de formulaire */
.slide-left {
  animation: slideLeft 0.3s ease-out forwards;
}

.slide-right {
  animation: slideRight 0.3s ease-out forwards;
}

.slide-up {
  animation: slideUp 0.3s ease-out forwards;
}

.shake {
  animation: shake 0.5s ease-in-out;
}

@keyframes slideLeft {
  to { transform: translateX(-100%); opacity: 0; }
}

@keyframes slideRight {
  to { transform: translateX(100%); opacity: 0; }
}

@keyframes slideUp {
  to { transform: translateY(-100%); opacity: 0; }
}

/* Responsive Design */
@media (max-width: 480px) {
  .login-form {
    max-width: 100%;
    padding: 0 20px;
  }

  .logo {
    max-width: 150px;
  }

  .input-field,
  .button {
    padding: 14px;
    font-size: 16px;
  }

  .welcome-logo {
    width: 150px;
  }
}

/* Support des préférences de mouvement réduit */
@media (prefers-reduced-motion: reduce) {
  .welcome-logo,
  .shape,
  .button,
  .input-field,
  .logo {
    animation: none;
    transition: none;
  }
}


.centered-image-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
