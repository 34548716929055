.cleanup-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  padding: 20px;
}

.cleanup-container {
  background-color: var(--card-background);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.header h1 {
  margin: 0;
}

.logout-button {
  background: none;
  border: none;
  color: var(--text-color);
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.logout-button:hover {
  background-color: var(--hover-color);
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: var(--text-color);
  font-size: 0.9rem;
}

.password-input {
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  transition: all 0.3s ease;
}

.password-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--primary-color-light);
}

.error-message {
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: -0.5rem;
}

.login-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-2px);
}

.login-button:active {
  transform: translateY(0);
}

.cleanup-container h1 {
  margin: 0 0 1rem;
  color: var(--text-color);
  text-align: center;
}

.warning {
  color: #e74c3c;
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
}

.status-container {
  margin-bottom: 2rem;
}

.status-indicator {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;
}

.status-indicator.idle {
  background-color: #f1f2f6;
  color: #636e72;
}

.status-indicator.cleaning {
  background-color: #74b9ff;
  color: white;
}

.status-indicator.success {
  background-color: #00b894;
  color: white;
}

.status-indicator.error {
  background-color: #e74c3c;
  color: white;
}

.status-message {
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 0.5rem;
  white-space: pre-wrap;
  font-family: monospace;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
}

.cleanup-button {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cleanup-button.idle {
  background-color: #e74c3c;
  color: white;
}

.cleanup-button.cleaning {
  background-color: #74b9ff;
  color: white;
  cursor: not-allowed;
}

.cleanup-button.success {
  background-color: #00b894;
  color: white;
}

.cleanup-button.error {
  background-color: #e74c3c;
  color: white;
}

.cleanup-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cleanup-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
