/* Variables de couleurs */
:root {
    --primary-color: #FF4458;         /* Couleur principale (rose rouge) */
    --secondary-color: #FF6B81;       /* Couleur secondaire (rose clair) */
    --background-color: #f8f9fa;      /* Couleur de fond claire */
    --background-light: #fafafa;      /* Couleur de fond très claire */
    --background-dark: #2c3e50;       /* Couleur de fond sombre */
    --background-color-dark: #1a1a1a;/* Couleur de fond très sombre */
    
    --card-background: #ffffff;       /* Fond des cartes (blanc) */
    --card-color: #FF4458;            /* Couleur de la carte principale */
    --text-color: #333;               /* Couleur principale du texte */
    --text-light: #666;               /* Texte de couleur intermédiaire */
    --text-lighter: #bbb;             /* Texte clair */
    --text-dark: #222;                /* Texte sombre */
    --text-lightest: #f0f0f0;         /* Texte très clair */
    
    --border-color: #ddd;             /* Couleur des bordures */
    --border-color-light: #eee;       /* Bordure plus claire */
    
    --primary-color-light: rgba(255, 68, 88, 0.2);  /* Version claire de la couleur principale */
    --secondary-color-light: rgba(255, 107, 129, 0.2); /* Version claire de la couleur secondaire */
    
    --male-color: #4A90E2;            /* Couleur associée aux hommes */
    --female-color: #FF6B81;          /* Couleur associée aux femmes */
    
    --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);       /* Ombre légère */
    --shadow-lg: 0 4px 12px rgba(0, 0, 0, 0.1);      /* Ombre plus forte */
    
    --gradient: linear-gradient(135deg, #FF6B81, #FF4458);  /* Dégradé principal */
    --card-gradient: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5)); /* Dégradé de carte */
  
    --font-mono: "Courier New", Courier, monospace; /* Police monospace */
  
    /* Variables de taille et espacement */
    --font-size-lg: 18px;             /* Taille de police large */
    --font-size-md: 16px;             /* Taille de police moyenne */
    --font-size-sm: 14px;             /* Taille de police petite */
    --font-size-xs: 12px;             /* Taille de police extra petite */
    --font-size-xl: 24px;             /* Taille de police extra large */
    --font-size-xxl: 28px;            /* Taille de police extra extra large */
  
    --spacing-xs: 8px;                /* Espacement très petit */
    --spacing-sm: 12px;               /* Espacement petit */
    --spacing-md: 16px;               /* Espacement moyen */
    --spacing-lg: 24px;               /* Espacement grand */
    --spacing-xl: 32px;               /* Espacement très grand */
  
    --border-radius: 8px;             /* Rayon de bordure normal */
    --border-radius-lg: 12px;         /* Rayon de bordure large */
    --border-radius-sm: 4px;          /* Rayon de bordure petit */
  
    --navbar-height: 60px;            /* Hauteur de la barre de navigation */
  
    /* Variables spécifiques pour les écrans et les icônes */
    --icon-size: 24px;                /* Taille des icônes par défaut */
    --avatar-size: 40px;              /* Taille des avatars */
    --small-avatar-size: 30px;        /* Taille des petits avatars */
    --large-avatar-size: 50px;        /* Taille des grands avatars */
  
    /* Variables de tailles spécifiques */
    --input-height: 40px;             /* Hauteur d'input */
    --input-radius: 10px;             /* Rayon des champs de texte */
  
    /* Variables pour l'animation */
    --transition: 0.3s ease-in-out;   /* Transition standard */
    --transition-fast: 0.2s ease-in-out; /* Transition plus rapide */
    --transition-slow: 0.5s ease-in-out; /* Transition plus lente */
  
    /* Variables pour les effets de bouton */
    --hover-color: rgba(255, 255, 255, 0.3); /* Couleur au survol des boutons */
    --focus-color: rgba(0, 123, 255, 0.3); /* Couleur au focus des boutons */
    
    --background-placeholder: rgba(255, 255, 255, 0.1); /* Fond des éléments de saisie */
    
    --input-border-focus: #007bff;   /* Bordure de champ focus */
    --input-shadow-focus: 0 0 5px rgba(0, 123, 255, 0.3); /* Ombre au focus */
  }
  
  /* Déclaration pour d'autres styles ou personnalisations spécifiques */
  