.chat-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /*background-color: var(--background-color);*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overscroll-behavior: none;
  touch-action: none;
}

.navbar {
  position: fixed;
  top: 15px;
  height: var(--navbar-height);
  /*background-color: var(--card-background);*/
  border-bottom: 1px solid var(--border-color);
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-md);
}

.navbar .nav-link {
  color: var(--text-color);
  cursor: pointer;
  padding: var(--spacing-xs) var(--spacing-md);
  border: none;
  background: none;
  font-size: var(--font-size-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  transition: var(--transition);
}

.navbar .nav-link:hover {
  color: var(--primary-color);
}

.navbar .nav-link i {
  font-size: var(--font-size-lg);
}

.chat-header {
  background-color: var(--card-background);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-sm);
  z-index: 10;
}

.chat-user-info {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.chat-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-user-details {
  display: flex;
  flex-direction: column;
}

.chat-user-name {
  font-weight: 600;
  font-size: var(--font-size-lg);
  color: var(--text-color);
}

.online-status {
  color: #28a745;
  font-size: var(--font-size-sm);
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column-reverse;
  gap: var(--spacing-md);
  margin-bottom: 80px;
  height: calc(100vh - calc(var(--navbar-height) + 80px));
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
}

.android-messages {
  transform: translateY(-70px);
}

.message {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-xs);
  max-width: 80%;
  opacity: 1;
  transform: scale(1) translateY(0);
}

.message-mine {
  margin-left: auto;
  flex-direction: row-reverse;
}

.message-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.message-content {
  background-color: var(--card-background);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
  position: relative;
}

.message-mine .message-content {
  background-color: var(--primary-color);
  color: white;
}

.message-text {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
}

.message-time {
  font-size: var(--font-size-xs);
  color: var(--text-light);
  margin-top: var(--spacing-xs);
  display: block;
}

.message-mine .message-time {
  color: rgba(255, 255, 255, 0.8);
}

.message-image-container {
  max-width: 300px;
}

.message-image {
  width: 100%;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}

.message-input-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-md);
  background-color: var(--card-background);
  border-top: 1px solid var(--border-color);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.message-input {
  flex: 1;
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
  outline: none;
  font-size: var(--font-size-md);
  transition: var(--transition);
}

.message-input:focus {
  border-color: var(--primary-color);
  box-shadow: var(--shadow-sm);
}

.attach-button,
.send-button {
  background: none;
  border: none;
  color: var(--primary-color);
  padding: var(--spacing-sm);
  cursor: pointer;
  font-size: var(--font-size-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}

.attach-button:hover,
.send-button:hover {
  color: var(--secondary-color);
}

.attach-button:disabled,
.send-button:disabled {
  color: var(--text-lighter);
  cursor: not-allowed;
}

/*.back-button {
  background: none;
  border: none;
  font-size: 20px;
  color: var(--text-color);
  padding: 8px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  color: var(--primary-color);
}*/

.upload-progress {
  height: 3px;
  background: #eee;
  margin-bottom: 10px;
}

.progress-bar {

  transition: width 0.3s ease;
}

/* Animation pour les nouveaux messages */
.message.new-message {
  animation: none;
}

.message.new-message.animate {
  animation: message-pop 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

@keyframes message-pop {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(20px);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05) translateY(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.message.own.new-message.animate {
  animation: message-pop-own 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

@keyframes message-pop-own {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(20px) translateX(20px);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05) translateY(10px) translateX(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0) translateX(0);
  }
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.image-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.image-modal-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.image-modal-content button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .message {
    max-width: 85%;
  }

  .message-content.media img,
  .message-content.media video {
    max-width: 250px;
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .message {
    max-width: 90%;
  }

  .message-content.media img,
  .message-content.media video {
    max-width: 200px;
    max-height: 250px;
  }

  .message-input-container {
    padding: 10px;
    padding-bottom:20px;
  }

  .message-input {
    padding: 8px 12px;
  }

  .attach-button,
  .send-button {
    font-size: 18px;
    padding: 6px;
  }
}
